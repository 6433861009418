import revive_payload_client_4sVQNw7RlN from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/trezlabs-frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/ubuntu/trezlabs-frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/home/ubuntu/trezlabs-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_b5LO69U7Vr from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt-sanctum-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/ubuntu/trezlabs-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import eventBusPlugin_UXUBb6nF3R from "/home/ubuntu/trezlabs-frontend/plugins/eventBusPlugin.js";
import primevue_TdXjRgL1MA from "/home/ubuntu/trezlabs-frontend/plugins/primevue.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_t2GMTTFnMT,
  plugin_b5LO69U7Vr,
  chunk_reload_client_UciE0i6zes,
  eventBusPlugin_UXUBb6nF3R,
  primevue_TdXjRgL1MA
]