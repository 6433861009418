import { default as icons_45pageMKYye6CJkBMeta } from "/home/ubuntu/trezlabs-frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as About9s86wF8YDpMeta } from "/home/ubuntu/trezlabs-frontend/pages/About.vue?macro=true";
import { default as Blog8IxhFBNpXaMeta } from "/home/ubuntu/trezlabs-frontend/pages/Blog.vue?macro=true";
import { default as Careersju8HRXmJxBMeta } from "/home/ubuntu/trezlabs-frontend/pages/Careers.vue?macro=true";
import { default as ContactAXriVWoXreMeta } from "/home/ubuntu/trezlabs-frontend/pages/Contact.vue?macro=true";
import { default as NearshorebEoP0TFSxPMeta } from "/home/ubuntu/trezlabs-frontend/pages/Nearshore.vue?macro=true";
import { default as Privacyqed2gjpsT6Meta } from "/home/ubuntu/trezlabs-frontend/pages/Privacy.vue?macro=true";
import { default as Servicesn2gNH6DpU5Meta } from "/home/ubuntu/trezlabs-frontend/pages/Services.vue?macro=true";
import { default as TermsUotkWhhRryMeta } from "/home/ubuntu/trezlabs-frontend/pages/Terms.vue?macro=true";
import { default as Articlesz9eVWPx08ZMeta } from "/home/ubuntu/trezlabs-frontend/pages/admin/Articles.vue?macro=true";
import { default as LoginkWTNHJcj34Meta } from "/home/ubuntu/trezlabs-frontend/pages/admin/Login.vue?macro=true";
import { default as _91slug_930cg8ZQrYA4Meta } from "/home/ubuntu/trezlabs-frontend/pages/articles/[slug].vue?macro=true";
import { default as _91slug_93l2UVtnaak8Meta } from "/home/ubuntu/trezlabs-frontend/pages/case-study/[slug].vue?macro=true";
import { default as indexWLJtVbw9dnMeta } from "/home/ubuntu/trezlabs-frontend/pages/case-study/index.vue?macro=true";
import { default as indexuMb2o0XphGMeta } from "/home/ubuntu/trezlabs-frontend/pages/index.vue?macro=true";
import { default as _91slug_932LdoXErNEuMeta } from "/home/ubuntu/trezlabs-frontend/pages/positions/[slug].vue?macro=true";
import { default as _91slug_93GSRF7TwZhLMeta } from "/home/ubuntu/trezlabs-frontend/pages/services/[slug].vue?macro=true";
import { default as _91slug_933aVS9GB9vaMeta } from "/home/ubuntu/trezlabs-frontend/pages/solutions/[slug].vue?macro=true";
export default [
  {
    name: icons_45pageMKYye6CJkBMeta?.name ?? "icons-page",
    path: icons_45pageMKYye6CJkBMeta?.path ?? "/_icons",
    meta: {...(icons_45pageMKYye6CJkBMeta || {}), ...{"layout":"svg-sprite"}},
    alias: icons_45pageMKYye6CJkBMeta?.alias || [],
    redirect: icons_45pageMKYye6CJkBMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: About9s86wF8YDpMeta?.name ?? "About",
    path: About9s86wF8YDpMeta?.path ?? "/About",
    meta: About9s86wF8YDpMeta || {},
    alias: About9s86wF8YDpMeta?.alias || [],
    redirect: About9s86wF8YDpMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/About.vue").then(m => m.default || m)
  },
  {
    name: Blog8IxhFBNpXaMeta?.name ?? "Blog",
    path: Blog8IxhFBNpXaMeta?.path ?? "/Blog",
    meta: Blog8IxhFBNpXaMeta || {},
    alias: Blog8IxhFBNpXaMeta?.alias || [],
    redirect: Blog8IxhFBNpXaMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Blog.vue").then(m => m.default || m)
  },
  {
    name: Careersju8HRXmJxBMeta?.name ?? "Careers",
    path: Careersju8HRXmJxBMeta?.path ?? "/Careers",
    meta: Careersju8HRXmJxBMeta || {},
    alias: Careersju8HRXmJxBMeta?.alias || [],
    redirect: Careersju8HRXmJxBMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Careers.vue").then(m => m.default || m)
  },
  {
    name: ContactAXriVWoXreMeta?.name ?? "Contact",
    path: ContactAXriVWoXreMeta?.path ?? "/Contact",
    meta: ContactAXriVWoXreMeta || {},
    alias: ContactAXriVWoXreMeta?.alias || [],
    redirect: ContactAXriVWoXreMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Contact.vue").then(m => m.default || m)
  },
  {
    name: NearshorebEoP0TFSxPMeta?.name ?? "Nearshore",
    path: NearshorebEoP0TFSxPMeta?.path ?? "/Nearshore",
    meta: NearshorebEoP0TFSxPMeta || {},
    alias: NearshorebEoP0TFSxPMeta?.alias || [],
    redirect: NearshorebEoP0TFSxPMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Nearshore.vue").then(m => m.default || m)
  },
  {
    name: Privacyqed2gjpsT6Meta?.name ?? "Privacy",
    path: Privacyqed2gjpsT6Meta?.path ?? "/Privacy",
    meta: Privacyqed2gjpsT6Meta || {},
    alias: Privacyqed2gjpsT6Meta?.alias || [],
    redirect: Privacyqed2gjpsT6Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Privacy.vue").then(m => m.default || m)
  },
  {
    name: Servicesn2gNH6DpU5Meta?.name ?? "Services",
    path: Servicesn2gNH6DpU5Meta?.path ?? "/Services",
    meta: Servicesn2gNH6DpU5Meta || {},
    alias: Servicesn2gNH6DpU5Meta?.alias || [],
    redirect: Servicesn2gNH6DpU5Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Services.vue").then(m => m.default || m)
  },
  {
    name: TermsUotkWhhRryMeta?.name ?? "Terms",
    path: TermsUotkWhhRryMeta?.path ?? "/Terms",
    meta: TermsUotkWhhRryMeta || {},
    alias: TermsUotkWhhRryMeta?.alias || [],
    redirect: TermsUotkWhhRryMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/Terms.vue").then(m => m.default || m)
  },
  {
    name: Articlesz9eVWPx08ZMeta?.name ?? "admin-Articles",
    path: Articlesz9eVWPx08ZMeta?.path ?? "/admin/Articles",
    meta: Articlesz9eVWPx08ZMeta || {},
    alias: Articlesz9eVWPx08ZMeta?.alias || [],
    redirect: Articlesz9eVWPx08ZMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/admin/Articles.vue").then(m => m.default || m)
  },
  {
    name: LoginkWTNHJcj34Meta?.name ?? "admin-Login",
    path: LoginkWTNHJcj34Meta?.path ?? "/admin/Login",
    meta: LoginkWTNHJcj34Meta || {},
    alias: LoginkWTNHJcj34Meta?.alias || [],
    redirect: LoginkWTNHJcj34Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/admin/Login.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930cg8ZQrYA4Meta?.name ?? "articles-slug",
    path: _91slug_930cg8ZQrYA4Meta?.path ?? "/articles/:slug()",
    meta: _91slug_930cg8ZQrYA4Meta || {},
    alias: _91slug_930cg8ZQrYA4Meta?.alias || [],
    redirect: _91slug_930cg8ZQrYA4Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l2UVtnaak8Meta?.name ?? "case-study-slug",
    path: _91slug_93l2UVtnaak8Meta?.path ?? "/case-study/:slug()",
    meta: _91slug_93l2UVtnaak8Meta || {},
    alias: _91slug_93l2UVtnaak8Meta?.alias || [],
    redirect: _91slug_93l2UVtnaak8Meta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/case-study/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWLJtVbw9dnMeta?.name ?? "case-study",
    path: indexWLJtVbw9dnMeta?.path ?? "/case-study",
    meta: indexWLJtVbw9dnMeta || {},
    alias: indexWLJtVbw9dnMeta?.alias || [],
    redirect: indexWLJtVbw9dnMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/case-study/index.vue").then(m => m.default || m)
  },
  {
    name: indexuMb2o0XphGMeta?.name ?? "index",
    path: indexuMb2o0XphGMeta?.path ?? "/",
    meta: indexuMb2o0XphGMeta || {},
    alias: indexuMb2o0XphGMeta?.alias || [],
    redirect: indexuMb2o0XphGMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932LdoXErNEuMeta?.name ?? "positions-slug",
    path: _91slug_932LdoXErNEuMeta?.path ?? "/positions/:slug()",
    meta: _91slug_932LdoXErNEuMeta || {},
    alias: _91slug_932LdoXErNEuMeta?.alias || [],
    redirect: _91slug_932LdoXErNEuMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/positions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GSRF7TwZhLMeta?.name ?? "services-slug",
    path: _91slug_93GSRF7TwZhLMeta?.path ?? "/services/:slug()",
    meta: _91slug_93GSRF7TwZhLMeta || {},
    alias: _91slug_93GSRF7TwZhLMeta?.alias || [],
    redirect: _91slug_93GSRF7TwZhLMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933aVS9GB9vaMeta?.name ?? "solutions-slug",
    path: _91slug_933aVS9GB9vaMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_933aVS9GB9vaMeta || {},
    alias: _91slug_933aVS9GB9vaMeta?.alias || [],
    redirect: _91slug_933aVS9GB9vaMeta?.redirect || undefined,
    component: () => import("/home/ubuntu/trezlabs-frontend/pages/solutions/[slug].vue").then(m => m.default || m)
  }
]